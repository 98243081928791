import React, { useState } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";
import { Link } from "gatsby"
import useUpdateEffect from "../hooks/use-update-effect";
import { useColourPalettes } from "../hooks/use-colour-palettes";
import { StaticImage } from "gatsby-plugin-image";
import { latestDate, maxDate } from "../helpers/form-validation-helper";

const FormInput = ({ type, label, value, onChange, onBlur, placeholder, disabled, validations, maxLength, min, max }) => {
    const [error, setError] = useState(() => null);
    useUpdateEffect(() => {
        if (validations?.length > 0) {
            const result = validations.find(f => f(value));
            setError(() => result ? result(value) : null);
        }
    }, [value])
    return (
        <label className="block">
            <span className="font-ovo text-base tracking-wider text-gray-800">{label}</span>
            <input
                value={value}
                onChange={(e) => onChange(() => e.target.value)}
                onBlur={onBlur}
                type={type}
                className="font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray
                mt-2 block w-full rounded-md border-gray-300 shadow-sm
                focus:border-pas-peach-dark focus:ring-1 focus:ring-pas-peach-darkest focus:ring-opacity-50"
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                min={min}
                max={max} />
            <p className="font-gotu text-red-500 text-[.65rem] tracking-wide mt-1 ml-2">{error}</p>

        </label>
    )
}

export const FormInputText = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"text"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputNumber = ({ label, value, onChange, placeholder, disabled, validations, onBlur, maxLength, min, max }) => {
    return (
        <FormInput type={"number"} label={label} value={value} onChange={onChange} placeholder={placeholder}
            disabled={disabled} validations={validations} onBlur={onBlur} maxLength={maxLength}
            min={min} max={max} />
    )
}

export const FormInputEmail = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"email"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputDate = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"date"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} min={latestDate} max={maxDate} />
    )
}

export const FormInputDateTime = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"datetime-local"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormInputTel = ({ label, value, onChange, placeholder, disabled, validations }) => {
    return (
        <FormInput type={"tel"} label={label} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} validations={validations} />
    )
}

export const FormButton = ({ label, onClick, disabled, size, isSecondary, className, isProcessing }) => {
    let padding;
    let fontWeight;
    let secondary = `bg-pas-white text-gray-800
    font-ovo text-sm md:text-sm tracking-widest
    shadow-md shadow-gray-100                
    border border-gray-300 rounded-md                        
    hover:shadow-gray-300`;
    let primary = ` bg-pas-peach-dark text-white 
    font-ovo text-base md:text-lg tracking-widest
    shadow-md shadow-gray-100                
    border border-transparent rounded-md                        
    hover:shadow-gray-300`;
    let theme = isSecondary ? secondary : primary;
    let cursor = isProcessing ? "cursor-progress" : disabled ? "cursor-not-allowed" : "";

    switch (size) {
        case "sm":
            padding = "py-1 px-2 mr-2 mb-2";
            fontWeight = "text-sm"
            break;
        default:
            padding = "py-3 px2";
            fontWeight = "font-normal"
            break;
    }
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={`            
                disabled:transform-none disabled:transition-none disabled:bg-gray-200 disabled:shadow-gray-400
                focus:outline-none focus:ring-1 focus:ring-offset-2
                focus:ring-pas-peach-darkest
                ${padding}
                ${fontWeight}
                ${theme}
                ${cursor}
                ${className}`}>

            <span className="inline-block align-middle">
                {label}
                {isProcessing ? <ProcessIconCake /> : <></>}
            </span>

        </button>
    )
}

const ProcessIcon = () =>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mb-1 animate-spin" viewBox="0 0 20 20" fill="currentColor">
        <path
            fillRule="evenodd"
            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
            clipRule="evenodd" />
    </svg>

const ProcessIconCake = () =>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mb-1 ml-2 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
    </svg>

const ProcessIconCakeFill = () =>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mb-1 animate-bounce" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M6 3a1 1 0 011-1h.01a1 1 0 010 2H7a1 1 0 01-1-1zm2 3a1 1 0 00-2 0v1a2 2 0 00-2 2v1a2 2 0 00-2 2v.683a3.7 3.7 0 011.055.485 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0 3.704 3.704 0 014.11 0 1.704 1.704 0 001.89 0A3.7 3.7 0 0118 12.683V12a2 2 0 00-2-2V9a2 2 0 00-2-2V6a1 1 0 10-2 0v1h-1V6a1 1 0 10-2 0v1H8V6zm10 8.868a3.704 3.704 0 01-4.055-.036 1.704 1.704 0 00-1.89 0 3.704 3.704 0 01-4.11 0 1.704 1.704 0 00-1.89 0A3.704 3.704 0 012 14.868V17a1 1 0 001 1h14a1 1 0 001-1v-2.132zM9 3a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm3 0a1 1 0 011-1h.01a1 1 0 110 2H13a1 1 0 01-1-1z" clipRule="evenodd" />
    </svg>

export const FormLinkButton = ({ label, to, className, isSecondary }) => {
    let secondary = `bg-pas-white text-pas-gray 
    shadow-md shadow-gray-100                
    border border-gray-300 rounded-md                        
    hover:shadow-gray-300`;
    let primary = `bg-pas-peach-dark text-white
    shadow-md shadow-gray-100                
    border border-transparent rounded-md                        
    hover:shadow-gray-300`;
    let theme = isSecondary ? secondary : primary;
    return (
        <Link
            to={to}
            className={`                               
                py-3 px-8
                focus:outline-none focus:ring-1 focus:ring-offset-2 
                focus:ring-pas-peach-darkest
                ${className}
                ${theme}
                `}>
            {label}</Link>
    )
}

export const FormInputAddressAutoComplete = ({ label, value, onChange, validations }) => {

    const [error, setError] = useState(() => null);
    const [isAuckland, setIsAuckland] = useState(() => false);

    useUpdateEffect(() => {
        if (validations?.length > 0) {
            const result = validations.find(f => f(value));
            const resultError = result ? result(value) : null;
            const outsideAucklandError = "Sorry, we currently only deliver in Auckland.";
            setError(() => resultError ? resultError : (!isAuckland ? outsideAucklandError : null));

        }
    }, [value, isAuckland])

    const { ref } = usePlacesWidget({
        apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            const auckland = place?.address_components.find(c => {
                const locality = c.types.find(t => t == "locality");
                const area = c.types.find(t => t == "administrative_area_level_1");
                return ((locality || area) && c?.short_name == "Auckland") ? c : null;
            })
            setIsAuckland(!!auckland);
            onChange(() => ref.current.value);
        },
        options: {
            types: ["(cities)"],
            componentRestrictions: { country: "nz" },
            fields: ["address_components"],
            types: ["address"],
        },
    });

    return (
        <label className="block">
            <span className="font-ovo text-base tracking-wider text-gray-800">{label}</span>
            <input
                ref={ref}
                defaultValue={value}
                type="text"
                className="block font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray
                mt-1 w-full rounded-md border-gray-300 shadow-sm
                focus:border-pas-peach-dark focus:ring-1 focus:ring-pas-peach-darkest focus:ring-opacity-50"
            />
            <p className="font-gotu text-red-500 text-[.65rem] tracking-wide mt-2 ml-2">{error}</p>
        </label>

    )
}

export const FormSelect = ({ label, value, onChange, placeholder, options, error, sublabel }) => {
    if (!options) return (<></>);
    const labelElement = !sublabel ? <span className="font-ovo text-base tracking-wider text-gray-800">{label}</span> :
        <div className='flex flex-col'>
            <span className="font-ovo text-base tracking-wider text-gray-800 mr-1">{label}</span>
            <span className="font-gotu text-[.65rem] tracking-wider text-pas-gray mt-1.5">{sublabel}</span>
        </div>;
    return (
        <label className="block">
            {labelElement}
            <select value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(() => e.target.value)}
                className="
                    block
                    w-full
                    mt-2
                    rounded-md
                    border-gray-300
                    shadow-sm
                    font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray
                    focus:border-pas-peach-dark focus:ring-1 focus:ring-pas-peach-darkest focus:ring-opacity-50">
                {options.map(x => <option key={x.key ?? x.value} value={x.value}>{x.label}</option>)}
            </select>
            <p className="font-gotu text-red-500 text-[.65rem] mt-2 ml-2">{error}</p>
        </label>
    )
}

export const FormSelectTime = ({ label, value, onChange }) => {
    const options = [];
    // for (let hour = 10; hour <= 17; hour++) {
    //     for (let minute = 0; minute < 60; minute += 15) {
    //         const minuteLabel = minute == 0 ? "00" : minute;
    //         const hourLabel = hour > 12 ? `${hour % 12}` : `${hour}`;
    //         if (hour == 17 && minute > 0) break;
    //         options.push({ value: `${hour}:${minute}`, label: `${hourLabel}:${minuteLabel} ${hour >= 12 ? "PM" : "AM"}` })
    //     }
    // }
    options.push({ value: `09:30 AM - 12:30 PM`, label: `09:30 AM - 12:30 PM` })
    options.push({ value: `12:00 PM - 03:00 PM`, label: `12:00 PM - 03:00 PM` })
    return (
        <FormSelect
            label={label}
            value={value}
            onChange={onChange}
            options={options}
        />
    )
}

export const FormTextArea = ({ label, value, onChange }) => {
    return (
        <label className="block">
            <span className=" text-pas-gray text-sm">{label}</span>
            <textarea
                value={value}
                onChange={(e) => onChange(() => e.target.value)}
                className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray
                    focus:border-pas-peach-dark focus:ring-1 focus:ring-pas-peach-darkest focus:ring-opacity-50" rows="4"></textarea>
        </label>
    )
}

export const FormRadioGroup = ({ label, value, onChange, groupName, options, inline }) => {
    return (
        <div className="flex flex-col">
            {(label) ? <span className="font-ovo text-base tracking-wider text-gray-800 mb-3">{label}</span> : <></>}
            <label className="inline-flex">
                <div className={!inline ? "flex flex-col gap-2" : "flex flex-wrap gap-2"}>
                    {options.map(
                        (x, xi) =>
                            <label key={x.value} className="inline-flex items-start sm:items-center pr-6">
                                <input
                                    value={x.value}
                                    name={groupName}
                                    type="radio"
                                    checked={x.value == value}
                                    onChange={(e) => onChange(() => e.target.value)}
                                    className="block rounded-full 
                                    h-5 w-5
                                    text-pas-peach-darkest
                                    transition duration-200
                                    bg-no-repeat bg-center bg-contain
                                    checked:bg-pas-peach-darkest checked:border-pas-peach-darkest 
                                    border-gray-300 shadow-sm 
                                    focus:border-pas-peach-dark focus:bg-pas-peach-darkest focus:ring-1 focus:ring-pas-peach-darkest focus:ring-opacity-50"
                                />
                                {(x.labels?.length > 0) ? <div className="flex flex-wrap items-center pt-0 sm:pt-1">
                                    {x.labels.map((y, yi) => <span key={yi} className={yi > 0 ? "font-gotu text-left text-xs md:text-[.78rem] tracking-wider leading-4 text-pas-gray sm:block ml-2 mb-0.5" : "font-ovo text-base tracking-wider text-gray-800 w-full sm:w-fit inline-block align-middle ml-2 mb-1 pt-0.5"}>{y}</span>)}
                                </div> : <></>}

                            </label>
                    )}
                </div>
            </label>
        </div>
    )
}

export const FormCheckbox = ({ label, value, onChange, className }) => {
    return (
        <div className="block">
            <div className="mt-2">
                <div>
                    <label className="inline-flex items-top">
                        <input
                            type="checkbox"
                            onChange={(e) => onChange(() => e.target.checked)}
                            className="
                                    h-5 w-5
                                    rounded
                                    border-gray-300
                                    text-pas-gray
                                    checked:text-pas-peach-dark
                                    shadow-sm
                                    focus:border-pas-peach-dark
                                    focus:ring-1
                                    focus:ring-offset-0
                                    focus:ring-pas-peach-darkest
                                    focus:ring-opacity-50"
                            checked={value} />
                        <span className={`ml-2 ${className}`}>{label}</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export const FormColorPicker = ({ label, value, onChange, groupName, options }) => {
    if (!options) return (<></>);
    const colourPalettes = useColourPalettes();
    return (
        <div className="flex flex-col">
            <span className='text-wider text-zinc-900 text-sm uppercase'>{label}</span>
            <label className="inline-flex">
                <div className="flex flex-wrap">
                    {
                        options.map(
                            (x, i) => {
                                const result = colourPalettes.find(c => c.colour === x.value);
                                if (!result) {
                                    {/* console.log("Unable to find color " + x.value); */}
                                    return <div key={i}></div>
                                }
                                const style = { "backgroundColor": result.hex };
                                return <label key={i} className="inline-flex items-center pr-2">
                                    <input
                                        value={x.value}
                                        name={groupName}
                                        type="radio"
                                        checked={x.value == value}
                                        onChange={(e) => onChange(() => e.target.value)}
                                        className="
                                        w-8 h-8 
                                        rounded-full inline-block 
                                        mt-2 py-1 mx-2 mb-2                                        
                                        cursor-pointer
                                        border-0
                                        bg-no-repeat bg-center bg-contain  
                                        shadow-md shadow-slate-300 
                                        focus:ring-1 focus:ring-pas-white focus:ring-opacity-0 focus:ring-transparent                                      
                                        checked:w-10 checked:h-10 checked:py-2 checked:mx-1 
                                        checked:shadow-slate-500 checked:shadow-lg"
                                        style={style}
                                    />
                                </label>
                            }
                        )}
                </div>
            </label>
        </div>
    )
}

export const FormColorPickerButton = ({ label, value, onChange, groupName, options }) => {
    if (!options) return (<></>);
    const colourPalettes = useColourPalettes();
    return (
        <div className="flex flex-col">
            <span className='font-ovo uppercase text-base tracking-wider text-gray-800 mb-1'>{label}</span>
            <label className="inline-flex">
                <div className="flex flex-wrap">
                    {
                        options.map(
                            (x, i) => {
                                const result = colourPalettes.find(c => c.colour === x.value);
                                if (!result) {
                                    {/* console.log("Unable to find color " + x.value); */}
                                    return <div key={i}></div>
                                }
                                const style = { "backgroundColor": result.hex };
                                return <label key={i} className="inline-flex items-center pr-2">
                                    <button>

                                    </button>
                                    <button
                                        type="button"
                                        disabled={x.value == value}
                                        onClick={(e) => onChange(() => x.value)}
                                        className="
                                        w-8 h-8 
                                        rounded-full inline-block 
                                        mt-2 py-1 mx-2 mb-2                                        
                                        cursor-pointer
                                        border-1 border-slate-100
                                        bg-no-repeat bg-center bg-contain  
                                        shadow-md shadow-slate-300 
                                        focus:ring focus:ring-pas-white focus:ring-opacity-0 focus:ring-transparent                                      
                                        disabled:w-10 disabled:h-10 disabled:py-2 disabled:mx-1 
                                        disabled:shadow-slate-500 disabled:shadow-lg"
                                        style={style}
                                    />
                                </label>
                            }
                        )}
                </div>
            </label>
        </div>
    )
}